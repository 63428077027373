<template>
  <div>
    <header class="h_header">
      <div class="h_top v-container d-flex align-items-center">
        <div class="h_logo">
          <!-- Logo goes here -->
          <!-- <img src="" alt=""> -->
          <img
            src="@/assets/images/anan-img/logo/logo_top.png"
            alt="logo"
            title="anan-cargo-logo"
          />
        </div>

        <!-- <div class="h_right">
          <p class="m-0">
            ต้องการความช่วยเหลือ?
            <NavBarI18n />
          </p>
        </div> -->
      </div>
    </header>

    <main>
      <div class="bg_content">
        <div class="bg_content2 p-md-5 p-1">
          <div class="row">
            <div class="col-md-6">
              <div class="text-center text-white h-100 mt-10">
                <div>
                  <b-img
                    src="@/assets/images/anan-img/svg/logo_anan.svg"
                    alt="logo"
                    title="anan-cargo-logo"
                    class="w-50"
                  />
                </div>

                <div class="mt-10 mb-10">
                  <h1 class="text-white" style="letter-spacing: 5px">
                    บริการรับนำเข้าสินค้า
                  </h1>
                  <h3 class="text-white">
                    <span class="text-warning">จากจีนมาไทย</span> ทุกรูปแบบ
                  </h3>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="bg-login">
                <div class="mb-2">
                  <h2>เข้าสู่ระบบ</h2>
                </div>
                <validation-observer ref="loginValidation">
                  <b-form class="auth-login-form mt-2" @submit.prevent>
                    <!-- email -->
                    <b-form-group label="ชื่อผู้ใช้งาน" label-for="login-email">
                      <validation-provider
                        #default="{ errors }"
                        name="Username/Email"
                        rules="required"
                      >
                        <b-form-input
                          id="login-email"
                          v-model="username"
                          :state="errors.length > 0 ? false : null"
                          name="login-Username/Email"
                          placeholder="username"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- forgot password -->
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="login-password">รหัสผ่าน</label>
                        <router-link class="cursor-pointer" :to="{ name: 'auth-forgot' }">
                          <small>ลืมรหัสผ่าน?</small>
                        </router-link>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="login-password"
                            v-model="password"
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            name="login-password"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- checkbox -->
                    <b-form-group>
                      <b-form-checkbox
                        id="remember-me"
                        v-model="status"
                        name="checkbox-1"
                      >
                        จดจำฉัน
                      </b-form-checkbox>
                    </b-form-group>

                    <!-- submit buttons -->
                    <b-button
                      type="submit"
                      variant="primary"
                      block
                      size="lg"
                      @click="validationForm"
                    >
                      เข้าสู่ระบบ
                    </b-button>
                  </b-form>
                </validation-observer>
                <!-- <div class="divider my-2">
                  <div class="divider-text">
                    or
                  </div>
                </div> -->
                <b-row>
                  <!-- <b-col
                    md="6"
                    cols="12"
                  >
                    <b-button
                      block
                      variant="outline-primary"
                      @click="notify"
                    >
                      <b-avatar
                        src="/icon/facebook.webp"
                        size="sm"
                      />
                      &nbsp; facebook
                    </b-button>
                  </b-col>

                  <b-col
                    md="6"
                    cols="12"
                  >
                    <b-button
                      block
                      variant="outline-primary"
                    >
                      <b-avatar
                        size="sm"
                        src="/icon/google.png"
                      />
                      &nbsp; Google
                    </b-button>
                  </b-col> -->

                  <b-col cols="12" class="text-center mt-2">
                    <span
                      >เพิ่งเคยเข้ามาใน Anan Cargo ใช่หรือไม่
                      <router-link
                        class="text-primary ms-2 mb-1 font-weight-bolder"
                        :to="{ name: 'register' }"
                        >สมัครใหม่</router-link
                      ></span
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <this-footer />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormCheckbox,
  BButton,
  BImg,
  BAvatar,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ThisFooter from "./component/ThisFooter.vue";

export default {
  components: {
    ThisFooter,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BAvatar,
    BImg,
    BForm,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: "",
      password: "",
      rememberMe: false,
      isPasswordVisible: false,
      requiredValidator: true,

      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              username: this.username,
              password: this.password,
              agent_id: 1,
            })
            .then((response) => {
              const userData = response.data;
              useJwt.setToken(response.data.token);
              useJwt.setRefreshToken(response.data.refreshToken);
              localStorage.setItem("userData", JSON.stringify(userData));
              localStorage.setItem("CheckClick", true);
              this.$ability.update(userData.ability);
              this.$router
                .push(getHomeRouteForLoggedInUser(userData))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title:
                        "ยินดีต้อนรับเข้าสู่ ระบบบริการ นำเข้าสินค้าจากจีนมาไทย ทุกรูปแบบ",
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: "You have successfully logged ",
                    },
                  });
                })
                .catch((error) => {
                  this.$refs.loginForm.setErrors(error.response.data.error);
                });
            })
            .catch((error) => {
              // this.SwalError(error.response.data.message)
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "เกิดข้อผิดพลาด",
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                  text: "ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้องค่ะ",
                },
              });
            });
        }
      });
    },
    onSubmit() {
      this.$refs.refVForm.validate().then((success) => {
        if (success) {
          this.$router.push({ name: "dashboard" });
        }
      });
    },
    SwalError(message) {
      this.$swal({
        title: "Error!",
        text: message,
        icon: "error",
        confirmButtonText: "Cool",
      });
    },
  },
};
</script>
